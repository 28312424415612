<template>
  <page-view class="register">
    <div class="title">注册判可狗账号</div>
    <div class="register-wrap">
      <div class="register-form">
        <Divider>账号信息</Divider>
        <Form :model="form" ref="form" :rules="rules">
          <FormItem prop="username">
            <Input v-model="form.username" placeholder="输入账号名"></Input>
          </FormItem>
          <FormItem prop="password">
            <Input type="password" password v-model="form.password" placeholder="请输入密码"></Input>
          </FormItem>
          <FormItem prop="checkPass">
            <Input type="password" password v-model="form.checkPass" placeholder="请确认密码"></Input>
          </FormItem>
          <Divider>账号信息</Divider>
          <FormItem prop="phonenumber">
            <Input v-model="form.phonenumber" placeholder="请输入手机号"></Input>
          </FormItem>
          <FormItem prop="code">
            <div class="flex">
              <Input v-model="form.code" placeholder="请输入验证码" style="margin-right: 20px;"></Input>
              <CountDown v-model="form.recordId" :tel="form.phonenumber" :templateType="3"></CountDown>
            </div>
          </FormItem>
          <FormItem>
            <Button type="primary" long @click="register">注册</Button>
            <p class="text-right"><a href="/">已有账号？立即登陆</a></p>
          </FormItem>
        </Form>
      </div>
    </div>
  </page-view>
</template>

<script>
import CountDown from '@/components/CountDown'
import { registerUser } from '@/api/user'
export default {
  components: {
    CountDown
  },
  data () {
    const checkTel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号码不合法'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        username: ''
      },
      rules: {
        username: [
          { required: true, message: '账户名不可为空' }
        ],
        phonenumber: [
          { required: true, message: '手机号不可为空' },
          { validator: checkTel }
        ],
        password: [
          { required: true, message: '密码不可为空' }
        ],
        checkPass: [
          { required: true, message: '请确认密码' },
          { validator: validatePass2 }
        ],
        code: [
          { required: true, message: '验证码不可为空' }
        ]
      }
    }
  },
  methods: {
    register () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { username, phonenumber, code, password, recordId } = this.form
          registerUser({ username, phonenumber, code, password, recordId }).then(res => {
            if (res.isSuccess) {
              this.$Message.success('注册成功,请先完成个人信息！')
              this.$router.push('/usercenter')
            } else {
              this.$Message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    margin: 50px 0;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #000000;
}
.register-wrap {
    width: 100%;
    padding: 100px 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.register-form {
    width: 360px;
    margin: 0 auto;
}

a {
    color: #67c5ec;
}
</style>
